import { BasePaginatedRequest } from 'models/common';
import client from 'api/client';
import { PeoplePaginatedData } from 'models/people';

export function impersonate(personId: string): Promise<any> {
  return client.post(`backoffice/people/impersonate/request/${personId}`);
}

export function sendTestPushNotification(personId: string): Promise<any> {
  return client.post(`backoffice/people/${personId}/send-test-push-notification`);
}

export function removeImpersonate(): Promise<any> {
  return client.delete(`backoffice/impersonate`);
}

export function get(req: BasePaginatedRequest): Promise<PeoplePaginatedData> {
  return client.get(`backoffice/people`, {
    params: req,
  });
}